import { graphql, useStaticQuery } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"
import { myContext } from "../../provider"
import "../css/global.css"
import Header from "./header"
import MobileNav from "./mobileNav"
import SplashScreen from "./splashScreen"

const Layout = ({ children }) => {
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
				}
			}
			logo: file(relativePath: { eq: "lupus-wa-logo.png" }) {
				childImageSharp {
					fixed(height: 120) {
						...GatsbyImageSharpFixed_withWebp
					}
				}
			}
			fluidLogo: file(relativePath: { eq: "lupus-wa-logo.png" }) {
				childImageSharp {
					fluid(maxHeight: 20) {
						...GatsbyImageSharpFluid_withWebp
					}
				}
			}
		}
	`)
	return (
		<myContext.Consumer>
			{context =>
				context.loading ? (
					<SplashScreen
						logo={<Img fixed={data.logo.childImageSharp.fixed} alt="logo" />}
					/>
				) : (
					<div className="font-sans text-gray-900">
						<Header
							logo={
								<Img
									style={{ display: "block" }}
									className="w-6 h-6"
									fixed={data.fluidLogo.childImageSharp.fluid}
									alt="logo"
								/>
							}
							siteTitle={data.site.siteMetadata.title}
						/>
						<main className="mb-16">
							<div className="mx-auto max-w-4xl py-4 px-6 md:px-12">
								{children}
							</div>
						</main>
						<MobileNav />
					</div>
				)
			}
		</myContext.Consumer>
	)
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout
