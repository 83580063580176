import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { FaCapsules, FaClipboardList } from "react-icons/fa"
import { TiInfoLarge, TiSupport, TiThMenu } from "react-icons/ti"

const MobileNavBtn = ({ partiallyActive, Icon, linkTo }) => (
	<Link
		partiallyActive={partiallyActive}
		activeClassName="active-mobile-nav-btn"
		to={linkTo}
		className="mobile-nav-btn text-gray-500"
	>
		<Icon className="w-full" />
	</Link>
)

MobileNavBtn.propTypes = {
	Icon: PropTypes.func.isRequired,
	linkTo: PropTypes.string.isRequired,
	partiallyActive: PropTypes.bool,
}

MobileNavBtn.defaultProps = {
	partiallyActive: true,
}

const MobileNav = () => {
	return (
		<div
			style={{ justifyItems: "center" }}
			className="text-2xl bg-purple-100 fixed border-gray-200 border-t-2 bottom-0 left-0 w-full border grid grid-cols-5 gap-1 bg-white z-50"
		>
			<MobileNavBtn linkTo="/information-about-lupus" Icon={TiInfoLarge} />
			<MobileNavBtn
				linkTo="/lupus-diagnostic-guidelines"
				Icon={FaClipboardList}
			/>
			<MobileNavBtn linkTo="/treatment-options" Icon={FaCapsules} />
			<MobileNavBtn linkTo="/lupus-resources" Icon={TiSupport} />
			<MobileNavBtn partiallyActive={false} linkTo="/" Icon={TiThMenu} />
		</div>
	)
}

export default MobileNav
