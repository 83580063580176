import React from "react"
import { Link } from "gatsby"
import { FaApple, FaRegSmile } from "react-icons/fa"
import { MdAndroid, MdHome, MdKeyboardArrowLeft } from "react-icons/md"

const Header = ({ logo }) => (
	<nav
		style={{
			gridTemplateColumns: "minmax(24px, auto) auto 1fr auto auto auto",
		}}
		className="grid gap-3 bg-purple-500 w-full py-4 px-4 md:px-16 text-white text-2xl"
	>
		<Link className="h-6 w-6" to="/">
			{logo}
		</Link>
		<Link className="h-6" to="/">
			<MdHome />
		</Link>
		<button
			className="h-6"
			style={{ justifySelf: "start" }}
			type="button"
			onClick={() => window.history.go(-1)}
		>
			<MdKeyboardArrowLeft />
		</button>
		<Link className="h-6" to="/ios">
			<FaApple />
		</Link>
		<Link className="h-6" to="/android">
			<MdAndroid />
		</Link>
		<Link className="h-6" to="/contact-us">
			<FaRegSmile />
		</Link>
	</nav>
)

export default Header
