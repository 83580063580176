// TODO: Double check looks on a landscape orientation on a small device
import React from "react"

const SplashScreen = ({ logo }) => {
	return (
		<div style={{ backgroundColor: "#5B438A" }} className="h-screen">
			<div className="pt-16 sm:pt-10 md:pt-6 sm:mb-4 md:mb-8 flex justify-center">
				{logo}
			</div>
			<div
				style={{
					backgroundImage: "url(./lupus-butterfly-background.png)",
					backgroundSize: "cover",
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center",
					height: "380px",
				}}
				className="text-center relative grid grid-cols-1 content-center"
			>
				<h1 className="text-white text-5xl">Be Lupus Wise</h1>
				<h2 style={{ color: "#E5783A" }} className="text-3xl">
					It could save your life
				</h2>
			</div>
		</div>
	)
}

export default SplashScreen
